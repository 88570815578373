<template>
  <b-modal
    v-model="modal"
    id="modallg"
    :title="title"
    size="md"
    @ok="submit"
    @close="$emit('close')"
    @hide="$emit('close')"
  >
  <template v-if="!ani">
    <b-form-group label="First Name" label-for="input4">
      <b-form-input
        type="text"
        id="input4"
        v-model="user.first_name"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Last Name" label-for="input3">
      <b-form-input
        type="text"
        id="input3"
        v-model="user.last_name"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Email" label-for="input1">
      <b-form-input
        type="email"
        id="input1"
        v-model="user.email"
      ></b-form-input>
    </b-form-group>
  </template>
    <b-form-group label="Phone" label-for="input2">
      <b-form-input
        type="number"
        id="input2"
        v-model="user.phone"
      ></b-form-input>
    </b-form-group>
    
    <b-form-group class="mt-2" v-slot="{ ariaDescribedby }" v-show="!type && !ani">
      <label>Groups:</label>
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="user.groups"
        :options="groups"
        :aria-describedby="ariaDescribedby"
        name="flavour-1"
        text-field="name"
        value-field="id"
        @change="onChange($event)"
      ></b-form-checkbox-group>
    </b-form-group>

    <!-- <b-form-group label="Group" label-for="input2" v-else> 
      <b-form-input
        type="number"
        id="input2"
        v-model="user.group"
      ></b-form-input>
    </b-form-group> -->
  </b-modal>
</template>

<script>
//import { mapGetters } from "vuex";

export default {
  data() {
    return {
      user: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        groups: [],
      },
      modal: false,
      isEdit: false,
      selectedGroups: [],
      showGroups: false,
      oldGroups: null,
    };
  },
  props: {
    type: {
      type: Boolean,
    },
    ani: {
      type: Object,
    },
    item: {
      type: Object,
    },
    title: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  computed: {
    groups() {
      return this.$store.state.groups;
    },
  },
  mounted() {
    this.modal = this.show;
    if (this.item) {
      this.user.first_name = this.item.first_name ? this.item.first_name : null;
      this.user.last_name = this.item.last_name ? this.item.last_name : null;
      this.user.email = this.item.email ? this.item.email : null;
      this.user.phone = this.item.phone ? this.item.phone : null;
      if (this.$route.path.includes("contacts")) {
        this.user.groups = [];
        this.item.groups.forEach((el) => {
          this.user.groups.push(el.id);
        });
      } else {
        this.user.groups = this.item.groups ? this.item.groups : [];
      }
    }
  },
  methods: {
    onChange(item) {
      // let old = this.oldGroups.split(",");
      // if (item.length < old.length) {
      //   old.forEach((el) => {
      //     const index = item.indexOf(el);
      //     if (index == -1) {
      //       old.splice(old.indexOf(el), 1);
      //       this.user.groups = old.join(",");
      //     }
      //   });
      // } else {
      //   this.user.groups = item.join(",");
      // }
      // this.oldGroups = this.user.groups;
    },
    submit() {
      this.$emit("submit", this.user);
    },
  },
};
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
</style>

<style>
.modal-footer {
  background: none;
}
.custom-control-label::after,
.custom-control-label::before {
  top: -0.1rem !important;
}
</style>