<template>
  <section class="wizard">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body custom-wizard">
            <vue-good-wizard
              :steps="steps"
              :onNext="nextClicked"
              :onBack="backClicked"
            >
              <div slot="page1">
                <section>
                  <div class="form-group">
                    <label>Campaign Name</label>
                    <input
                      type="text"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                      v-model="item.name"
                    />
                    <p v-if="campaignNameError" class="errText">{{ campaignNameError }}</p>
                  </div>
                </section>
              </div>
              <div slot="page2">
                   <div class="d-flex my-2">
        <b-form-radio
          :value="true"
          :key="item.id"
          v-model="rotatingType"
          class="radio d-flex align-items-center mr-4"
          ><span class="radio-span">Rotating Caller ID</span></b-form-radio
        >
        <b-form-radio
          :value="false"
          v-model="rotatingType"
          class="radio d-flex align-items-center"
        >
         <span class="radio-span">Fixed Caller ID</span>
        </b-form-radio>
      </div>
                <section v-if="!rotatingType">
                  <div class="form-group">
                    <label>Caller Number 1</label>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter number"
                      v-model="item.ani_1"
                    />
                  </div>
                  <div class="form-group">
                    <label>Caller Number 2</label>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter number"
                      v-model="item.ani_2"
                      @keypress="isNumber($event)"
                    />
                  </div>
                </section>
                <section v-else>

                  <div class="form-group">
                    <label>Ani Group</label>
                    <b-form-select
                      :options="ani_list"
                      v-model="item.ani_group_id"
                      size="sm"
                    >
                          <b-form-select-option :value="null" disabled>Select ani group</b-form-select-option>

                    </b-form-select>
                  </div>
                  <div class="form-group">
                    <label>Ani Selector Type</label>
                    <b-form-select
                      :options="ani_types"
                      v-model="item.ani_selector_type"
                      size="sm"
                    />
                  </div>
                  <div class="form-group">
                    <label>Max Usage Per Ani</label>
                    <input
                      type="number"
                      class="form-control"
                      aria-describedby="emailHelp"
                      placeholder="Enter number"
                      v-model="item.max_usage_per_ani"
                      @keypress="isNumber($event)"
                    />
                  </div>
                </section>
              </div>
              <div slot="page3">
                <section>
                  <div>
                    <div class="mt-3">
                      <button
                        class="add btn font-weight-bold ml-0 mr-2 mt-lg-0 audio-buttons"
                        :class="[step2Type == 0 ? 'btn-primary' : 'btn-info']"
                        @click="
                          (step2Type = 0),
                            (recordFileName = null),
                            (recordAudio = null),
                            (audioFile = null)
                        "
                        v-if="audios.length > 0"
                      >
                        Select an Existing Recording
                      </button>
                      <button
                        class="add btn font-weight-bold ml-0 mr-2 mt-lg-0 audio-buttons"
                        :class="[step2Type == 1 ? 'btn-primary' : 'btn-info']"
                        @click="(step2Type = 1), (audioFile = null)"
                      >
                        Record via Phone
                      </button>
                      <button
                        class="add btn font-weight-bold ml-0 mr-2 mt-lg-0 audio-buttons"
                        :class="[step2Type == 2 ? 'btn-primary' : 'btn-info']"
                        @click="
                          (step2Type = 2),
                            (recordFileName = null),
                            (recordAudio = null)
                        "
                      >
                        Upload a Recording
                      </button>
                    </div>
                    <div class="mt-4">
                      <div v-if="step2Type == 0">
                        <b-form-group label="Select audio" label-for="input11">
                          <b-form-select
                            :options="audios"
                            v-model="item.media_id"
                          />
                        </b-form-group>
                      </div>
                      <div v-if="step2Type == 1">
                        <b-form-group
                          label="File name"
                          label-for="input2"
                          class="record-file-name mt-2"
                        >
                          <b-form-input
                            type="text"
                            id="input2"
                            v-model="recordFileName"
                          ></b-form-input>
                        </b-form-group>
                        <div
                          class="d-flex align-items-center record-audio"
                        >
                          <audio-recorder
                            :attempts="1"
                            :before-recording="recordAudio"
                            :pause-recording="recordAudio"
                            :after-recording="recordAudio"
                            :select-record="recordAudio"
                            :before-upload="recordAudio"
                            :successful-upload="recordAudio"
                            :failed-upload="recordAudio"
                          />
                        </div>
                      </div>
                      <div v-if="step2Type == 2">
                        <b-form-group label="Upload file" label-for="input8">
                          <b-form-file
                            v-model="audioFile"
                            id="inpu8"
                            accept=".wav, .mp3"
                            :state="audioFile ? Boolean(audioFile) : null"
                            placeholder="Choose a file..."
                          ></b-form-file>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div slot="page4">
                <section>
                  <div class="mt-3">
                    <!-- <button
                      class="add btn font-weight-bold ml-0 mr-2 mt-lg-0"
                      :class="[step3Type == 0 ? 'btn-primary' : 'btn-info']"
                      @click="step3Type = 0"
                    >
                      Choose a Recipients List
                    </button> -->
                    <!-- <button
                      class="add btn font-weight-bold ml-0 mr-2 mt-lg-0"
                      :class="[step3Type == 2 ? 'btn-primary' : 'btn-info']"
                      @click="step3Type = 2"
                    >
                      Upload a Recipients List
                    </button> -->
                  </div>
                  <div class="mt-3">
                    <div>
                      <b-form-group
                        class="mt-2"
                        label="Groups:"
                        v-slot="{ ariaDescribedby }"
                        v-if="step3Type == 0"
                      >
                        <b-form-checkbox-group
                          id="checkbox-group-1"
                          class="d-flex flex-column"
                          v-model="item.groups"
                          :options="groups"
                          :aria-describedby="ariaDescribedby"
                          text-field="name"
                          value-field="name"
                          name="flavour-1"
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <b-form-group
                        label="Upload file"
                        label-for="input8"
                        v-if="step3Type == 2"
                      >
                        <b-form-file
                          v-model="contactFile"
                          id="inpu8"
                          placeholder="Choose a file..."
                        ></b-form-file>
                      </b-form-group>
                    </div>
                  </div>
                </section>
              </div>
              <div slot="page5">
                <section>
                  <div class="mt-3">
                    <button
                      class="add btn font-weight-bold ml-0 mr-2 mt-lg-0 send-buttons"
                      :class="[step4Type == 0 ? 'btn-primary' : 'btn-info']"
                      @click="step4Type = 0"
                    >
                      Send Immediately
                    </button>
                    <button
                      class="add btn font-weight-bold ml-0 mr-2 mt-lg-0 send-buttons"
                      :class="[step4Type == 1 ? 'btn-primary' : 'btn-info']"
                      @click="step4Type = 1"
                    >
                      Choose Delivery Date & Time
                    </button>
                  </div>
                  <div class="mt-3">
                    <span v-if="step4Type == 0"
                      >*Upon submission this campaign will immediately begin
                      sending</span
                    >
                  </div>
                  <b-form-group
                    label="Select date and time"
                    class="date"
                    v-if="step4Type == 1"
                  >
                    <datetime
                      v-model="date"
                      type="datetime"
                      :auto="true"
                      @input="addDelay"
                    ></datetime>
                  </b-form-group>
                </section>
              </div>
            </vue-good-wizard>
          </div>
        </div>
      </div>
    </div>
    <Create
      :show="showCreate"
      v-if="showCreate"
      @close="showCreate = false"
      @create="loadItems"
    />
    <UploadWrapper
      ref="uploadWrapper"
      @id="item.media_id = $event"
      @error="errorUpload"
    />
  </section>
</template>

<script>
import { GoodWizard } from "vue-good-wizard";
import Create from "../components/contacts/Create";
import { mapState, mapGetters, mapActions } from "vuex";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import UploadWrapper from "@/components/UploadWrapper";
export default {
  name: "wizard",
  components: {
    "vue-good-wizard": GoodWizard,
    Create,
    Datetime,
    UploadWrapper,
  },
  data() {
    return {
      type: 0,
      step2Type: 0,
      step3Type: 0,
      step4Type: 0,
      showStopTime: false,
      showCreate: false,
      audioFile: null,
      recordFile: null,
      recordFileName: null,
      contactFile: null,
      rotatingType: false,
      error: null,
      campaignNameError: null,
      item: {
        ani_1: "",
        ani_2: "",
        name: null,
        media_id: null,
        groups: [],
        delay_hrs: 0,
        ani_group_id: null,
        ani_selector_type: 1,
        max_usage_per_ani: 1,
      },
      date: null,
      file: null,
      ani_types: [
        { text: "Random", value: 1 },
        { text: "Round Robin", value: 2 },
      ],
      steps: [
        {
          label: "Information",
          slot: "page1",
        },
        {
          label: "Caller ID",
          slot: "page2",
        },
        {
          label: "Recording",
          slot: "page3",
        },
        {
          label: "Recipients",
          slot: "page4",
        },
        {
          label: "Send Time",
          slot: "page5",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["groups", "audios"]),
    ani_list() {
      return this.$store.state.ani_list;
    },
  },
  async created() {
    await this.$store.dispatch("loadGroups", true);
  },
  async mounted() {
    this.$store.commit("updateLoading", true);
    this.initData();
  },
  methods: {
    ...mapActions(["loadGroups"]),
    async initData() {
      await this.$store.dispatch("loadGroups", false);
      await this.$store.dispatch("loadFiles", "info");
      if (this.audios.length == 0) {
        this.step2Type = 1;
      }
    },
    recordAudio(data) {
      this.recordFile = data.blob;
    },
    addDelay() {
      const now = this.$moment().unix();
      const select = this.$moment(this.date).unix();
      if (select > now) {
        this.item.delay_hrs = this.getHours(select - now);
      } else this.item.delay_hrs = 0;
    },
    getHours(val) {
      return val / 3600;
    },
    isNumber(evt) {
      evt = (evt) ? evt : window.event
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    errorUpload() {
      this.error = true;
      this.$swal.fire(
        "Error!",
        "Error loading one of the files, please try again",
        "error"
      );
      // this.clearFiles();
      setTimeout(() => {
        this.error = false;
      }, 3000);
    },
    clearFiles() {
      this.audioFile = null;
      this.contactFile = null;
      this.recordFile = null;
    },
    async save() {
      if (this.audioFile || this.contactFile || this.recordFile) {
        let emitAudio = { type: null, file: null, fileName: null };
        if (this.audioFile) {
          emitAudio.type = 1;
          emitAudio.file = this.audioFile;
          await this.$refs.uploadWrapper.uploadAudio(emitAudio);
        }
        if (this.recordFile) {
          emitAudio.type = 3;
          emitAudio.file = this.recordFile;
          emitAudio.fileName = this.recordFileName
            ? this.recordFileName
            : "Noname";
          await this.$refs.uploadWrapper.uploadAudio(emitAudio);
        }
        if (this.contactFile) {
          await this.$refs.uploadWrapper.uploadGroups(this.contactFile);
        }
        if (!this.error) {
          this.create();
        }
      } else {
        this.create();
      }
    },
    create() {
      this.item.groups =
        this.item.groups && Array.isArray(this.item.groups)
          ? this.item.groups.join(",")
          : null;
      if (this.item.delay_hrs !== 0) {
        this.addDelay();
      }
      this.item.ani_1 = parseInt(this.item.ani_1);
      this.item.ani_2 = parseInt(this.item.ani_2);
      const url =
        !this.rotatingType
          ? "/campaigns/create/from_contacts"
          : "/campaigns/create/from_ani_group";
      this.$axios
        .post(url, this.item)
        .then((res) => {
          this.$swal
            .fire("Created!", res.data.message, "success")
            .then((result) => {
              if (result.value) {
                this.$router.push("/");
              }
            });
        })
        .catch((err) => {
          const msg = err.response.data.error || err.response.data.error;
          if (msg.includes("Failing row contains")) {
            this.$swal.fire("Error!", "Complete all steps, please", "error");
          } else {
            this.$swal.fire("Error!", msg, "error");
          }
          this.item.groups =
            this.item.groups.length > 0 ? this.item.groups.split(",") : [];

          // this.clearFiles;
        });
    },
    nextClicked(currentPage) {

      if (currentPage == 0 && !this.item.name) {
        this.campaignNameError = 'Please enter Campaign Name'
        return
      }else {
        this.campaignNameError = null
      }

      if (currentPage == 4) {
        this.save();
      }
      return true;
    },
    backClicked() {
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
span {
  font-size: 14px;
}
.errText {
  margin-top: 10px;
  color: #e94437;
}
.col-form-label {
  padding-bottom: 0.5rem;
  font-size: 13px;
}
.ar .ar-content {
  margin-bottom: 1rem;
}
.radio-span {
  margin-top: -3px;
  display: block;
}
.custom-select {
  height: 46px;
}
.record-audio {
  width: 360px;
  @media (max-width: 481px) {
    width: auto;
  }
}
.audio-buttons, .send-buttons {
  margin-bottom: 15px;
  @media (max-width: 481px) {
    height: auto;
  }
}
</style>
<style>
.record-file-name .form-control {
  padding: 0 0.75rem;
  max-height: 35px;
}

</style>