<template>
  <Form
    :show="show"
    :type="type"
    :ani="ani"
    @submit="create"
    title="Create Number"
    @close="$emit('close')"
    @hide="$emit('close')"
  />
</template>

<script>
import Form from "./Form";

export default {
  components: {
    Form,
  },
  props: {
    show: {
      type: Boolean,
    },
    ani: {
      type: Object,
    },
    type: {
      type: Boolean,
    },
    selectedGroup: {
      type: Object,
    },
  },
  methods: {
    async create(user) {
      if (!this.ani && this.selectedGroup) {
        user.groups = [this.selectedGroup.id];
      }
      if(this.ani && this.selectedGroup) {
        this.ani.ani_list.unshift(parseInt(user.phone))
        user = this.ani
      }
      const url = this.ani && this.selectedGroup ? `/ani_groups/update/${user.id}` : '/contacts/create'

      if (this.ani == null) {
        if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(user.email)) {
          console.log('email is correct')
        }else {
          this.$swal.fire("Error", 'Please enter a valid email address', "error");
          return;
        }
      }

      if (this.ani == null) {
        if (user.phone == null) {
          this.$swal.fire("Error", 'Please enter a valid phone number', "error");
          return;
        }
      }else {
        if (user.ani_list[0] == null || isNaN(parseFloat(user.ani_list[0]))) {
          this.$swal.fire("Error", 'Please enter a valid phone number', "error");
          return;
        }
      }

      await this.$axios
        .post(url, user)
        .then((res) => {
          this.$emit('close');
          this.$parent.initData();
          this.$swal.fire("Contact created!", "", "success");
        })
        .catch((err) => {
          this.$emit("close");
          this.$swal.fire("Error", err.response.data.error, "error");
        });
    },
  },
};
</script>

